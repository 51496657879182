/* ##### 3.0 Wellcome Area CSS ##### */
$bgColor: #121212;
$btnColor: #82DDFB;

.HomeDemo1 {
  .welcome_area {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 900px;
    overflow: hidden;
    background-color: $bgColor;
  }

  .Home1WelcomeAreaIMG {
    min-height: 100%;
    background: $bgColor;
    z-index: 100;
    background: url(../../assets/img/bg-img/top-bg.png) no-repeat center center;
    background-size: cover;
  }

  .hero-content {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .welcome-content {
    position: relative;
    z-index: 1;
  }

  .welcome-content h1 {
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #fff;
  }

  .welcome-content h1.artficial {
    font-size: 38px
  }

  .welcome-content p {
    font-size: 18px;
    color: #fff;
    margin-bottom: 30px;
  }

  .who-we-contant h4 {
    color: #fff;
  }

  p.artficial {
    padding-left: 20px;
    border-left: 1px solid #fff;
    position: relative;
  }

  p.artficial:before {
    content: '';
    position: absolute;
    left: -5px;
    top: -8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #fff
  }

  .welcome-content-small {
    margin-bottom: 50px
  }

  .welcome-content-small h4 {
    margin-bottom: 20px
  }

  .welcome-content-small p.artficial {
    font-size: 12px
  }

}

.dream-btn {
  position: relative !important;
  z-index: 1 !important;
  min-width: 160px !important;
  height: 48px !important;
  line-height: 48px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
  display: inline-block !important;
  padding: 0 20px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  background-size: 200% auto !important;
  color: $bgColor !important;
  border-radius: 14px !important;
  background-color: $btnColor;
  -webkit-transition: all 500ms !important;
  transition: all 500ms !important;
}

.dream-btn:hover {
  background-position: right center;
  color: #fff;
}

@media (max-width: 992px) {
  .hero-content .ico-counter,
  .HomeDemo1 .hero-content .service-img-wrapper .image-box {
    margin-top: 0 !important
  }

}


@media (max-width: 1200px) {
  .HomeDemo1 .main-ilustration-3 {
    background-size: contain;
    background-position: left;
  }
  .HomeDemo1 .main-ilustration-3 .inovation {
    right: 0 !important;
    top: 5%;
    display: inline-table;
  }
  .HomeDemo1 .main-ilustration-3 .inovation.num2 {
    right: 5%;
    top: 27%;
  }
}

@media (max-width: 767px) {
  .HomeDemo1 .welcome-small-wrapper {
    position: absolute;
    width: 38%;
  }
  .HomeDemo1 .welcome-content-small {
    position: absolute;
    top: -30px;
  }
  .HomeDemo1 .welcome-content.ill {
    margin-top: 120px !important
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  .HomeDemo1 .welcome-content {
    vertical-align: middle;
  }
}
