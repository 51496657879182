/***
====================================================================
  Google fonts
====================================================================

***/

@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700');

/***

====================================================================
  Import All Css
====================================================================

***/

@import 'animate.css';
@import 'about-sonar.css';
@import 'classy-nav.min.css';
@import 'animated-headline.css';
@import 'font-awesome.min.css';
@import 'themify-icons.css';

/***

====================================================================
  General css style
====================================================================

***/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth !important;

}

body {
    font-family: 'Poppins', sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
    color: #fff;
    line-height: 1.4;
    font-weight: 500;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none !important;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    outline: none;
}

li {
    list-style: none;
}

p {
    line-height: 1.9;
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
}

img {
    max-width: 100%;
    height: auto;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

#home,
#about,
#network,
#roadmap,
#contact{
    scroll-behavior: smooth;
}

#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    top: 0;
    left: 0;
    background: #080808;
}

.preload-content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -35px;
    margin-left: -35px;
    z-index: 100;
}

#dream-load {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 3px;
    border-style: solid;
    border-color: transparent;
    border-top-color: rgba(255, 255, 255, 0.5);
    -webkit-animation: dreamrotate 2400ms linear infinite;
    animation: dreamrotate 2400ms linear infinite;
    z-index: 999;
}

#dream-load:before {
    content: "";
    position: absolute;
    top: 7.5px;
    left: 7.5px;
    right: 7.5px;
    bottom: 7.5px;
    border-radius: 50%;
    border: 3px;
    border-style: solid;
    border-color: transparent;
    border-top-color: rgba(255, 255, 255, 0.5);
    -webkit-animation: dreamrotate 2700ms linear infinite;
    animation: dreamrotate 2700ms linear infinite
}

#dream-load:after {
    content: "";
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    border-radius: 50%;
    border: 3px;
    border-style: solid;
    border-color: transparent;
    border-top-color: rgba(255, 255, 255, 0.5);
    -webkit-animation: dreamrotate 1800ms linear infinite;
    animation: dreamrotate 1800ms linear infinite
}

@-webkit-keyframes dreamrotate {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

@keyframes dreamrotate {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

.mt-15 {
    margin-top: 15px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-150 {
    margin-top: 150px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-100 {
    margin-right: 100px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-100 {
    margin-bottom: 100px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-100 {
    margin-left: 100px;
}

.section-padding-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.section-padding-100-70 {
    padding-top: 100px;
    padding-bottom: 70px;
}

.relative{
    position: relative;
}
.section-padding-0-100 {
    padding-top: 0;
    padding-bottom: 100px;
}
.section-padding-0-0 {
    padding-top: 0;
    padding-bottom: 0;
}
.section-padding-100-0 {
    padding-top: 100px;
    padding-bottom: 0;
}

.section-padding-100-50 {
    padding-top: 100px;
    padding-bottom: 50px;
}

.section-padding-0-50 {
    padding-top: 0;
    padding-bottom: 50px;
}

.section-padding-50-0 {
    padding-top: 50px;
    padding-bottom: 0;
}

.section-padding-200 {
    padding-top: 200px;
    padding-bottom: 200px;
}

.section-padding-0-200 {
    padding-top: 0;
    padding-bottom: 200px;
}

.section-padding-200-0 {
    padding-top: 200px;
    padding-bottom: 0;
}

.section-padding-200-100 {
    padding-top: 200px;
    padding-bottom: 100px;
}

.section-padding-150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.section-padding-150-0 {
    padding-top: 150px;
    padding-bottom: 0;
}

.section-padding-0-150 {
    padding-top: 0;
    padding-bottom: 150px;
}

.section-padding-50-150 {
    padding-top: 50px;
    padding-bottom: 150px;
}

.section-heading {
    position: relative;
    z-index: 1;
    margin-bottom: 60px;
    color: #FFFFFF;
}

.section-heading h2 {
    margin-top: 25px;
    font-size: 45px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 25px;
    white-space: pre-wrap;
}

.section-heading > p {
    line-height: 2;
    font-size: 16px;
    max-width: 850px;
    margin: 0 auto;
    color: #EBEBEB;
    white-space: pre-wrap;
}

#scrollUp {
    bottom: 130px;
    font-size: 12px;
    line-height: 22px;
    right: 30px;
    width: 100px;
    background-color: transparent;
    color: #fff;
    text-align: center;
    height: 20px;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    text-transform: uppercase;
    transform: rotate(-90deg);
}

#scrollUp:before {
    position: absolute;
    width: 30%;
    height: 2px;
    background-color: #fff;
    content: "";
    top: 10px;
    right: 100%;
    z-index: -200 !important;
}

.height-400 {
    height: 400px;
}

.height-600 {
    height: 600px;
}

.height-700 {
    height: 700px;
}

.height-800 {
    height: 800px;
}

.height-900 {
    height: 900px;
}

.height-1000 {
    height: 1000px;
}

.dream-btn {
    position: relative;
    z-index: 1;
    min-width: 160px;
    height: 48px;
    line-height: 48px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    display: inline-block;
    padding: 0 20px;
    text-align: center;
    text-transform: uppercase;
    background-size: 200% auto;
    color: #121212;
    box-shadow: 0 30px 20px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    background-color: #82DDFB;
    -webkit-transition: all 500ms;
    transition: all 500ms;
}

.dream-btn:hover {
    background-position: right center;
    color: #fff;
}

/* ##### Subscribe form area CSS ##### */

.welcome-content .subscribe-email {
    width: 70%;
    height: 55px;
    border: none;
    padding: 0 25px;
    border-radius: 8px;
    font-size: 12px;
    font-style: italic;
}

.fullwidth {
    width: 100vw !important;
    height: 100vh !important;
}
/* ##### Slider Area CSS ##### */

.welcome_area .welcome_slides .owl-prev,
.welcome_area .welcome_slides .owl-next {
    color: #fff;
    font-size: 24px;
    left: -100px;
    margin-top: -44px;
    position: absolute;
    text-align: center;
    top: calc(50% + 90px);
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    z-index: 99;
}

.welcome_area .welcome_slides .owl-next {
    right: -100px;
    left: auto;
}

.welcome_area .welcome_slides:hover .owl-next {
    right: 30px;
}

.welcome_area .welcome_slides:hover .owl-prev {
    left: 30px;
}


/* ##### Timeline CSS ##### */

.timelineBox {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 2px;
}

.timelineHeader {
    padding: 2rem;
    background: #e91e63;
    position: relative;
    z-index: 3;
}

.timelineHeader h3 {
    font-size: 2rem;
    margin: 0;
}

.timelineHeader h3 + span {
    font-size: 1.2rem;
    color: #fff;
}

.timelineBody {
    max-height: 480px;
    overflow-x: hidden;
    overflow-y: auto;
}

.timelineBody .timeline {
    padding: 2em;
    margin: 0;
    list-style: none;
    position: relative;
    z-index: 2;
}

.timelineBody .timeline li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 1em 0;
    position: relative;
}
.timelineBody .timeline li:before{
    position: absolute;
    content: '';
    left: 7px;
    top: 0;
    width: 1px;
    height: 100%;
    background: #25cbd3
}

.timelineBody .timeline li:last-child {
    margin-bottom: 0;
}

.timelineBody .timeline .timelineDot {
    height: 15px;
    width: 15px;
    background: #fff;
    border-radius: 2rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15px;
    flex: 0 0 15px;
    margin-right: auto;
    margin-top: 6px;
    box-shadow: 0 0 8px #56cb5f;
}

.timelineBody .timeline .timelineDot:after {
    content: '';
    position: absolute;
    top: 25px;
    left: 3px;
    height: 9px;
    width: 9px;
    background: #25cbd3;
    border-radius: 50%;
}

.timelineBody .timeline .timelineDate {
    font-size: 14px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80px;
    flex: 0 0 80px;
    padding: 0;
}

.timelineBody .timeline .timelineDate p {
    color: #25cbd3;
}

.timelineBody .timeline .timelineWork {
    font-size: 16px;
    margin-left: auto;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
}

.timelineBody .timeline .timelineWork h6 {
    color: #fff;
}

.timelineBody .timeline .timelineWork span {
    display: block;
    color: #bdbdbd;
    font-size: 13px;
}

/* ##### Sidebar CSS ##### */

.search-widget-area form {
    position: relative;
    z-index: 1;
}

.search-widget-area form input {
    width: 100%;
    height: 45px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    background-color: transparent;
    padding: 0 15px;
    color: #fff;
    font-size: 12px;
    border-radius: 30px;
}

.search-widget-area form button {
    width: 60px;
    height: 45px;
    background-color: transparent;
    padding: 0 15px;
    color: #fff;
    font-size: 14px;
    border-radius: 30px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
}

.widget-title h5 {
    margin-bottom: 30px;
    font-size: 18px;
    text-transform: capitalize;
    border-bottom: 1px solid #fff;
    padding: 0 0 5px 0;
}

.dont-miss-post-content > a {
    font-size: 16px;
    color: #fff;
    display: block;
    margin-top: 15px;
}

.dont-miss-post-content > span {
    font-size: 12px;
    color: #fff;
    display: block;
    text-transform: uppercase;
}

.subscribe-form input {
    width: 100%;
    height: 45px;
    border-radius: 45px;
    border: none;
    padding: 0 20px;
    font-size: 12px;
    font-style: italic;
    color: #fff;
    margin-bottom: 15px;
    background-color: rgba(255, 255, 255, 0.1);
}

.subscribe-form button {
    width: 100%;
    height: 45px;
    border-radius: 45px;
    border: none;
    font-size: 12px;
    padding: 0;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 15px;
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
}
