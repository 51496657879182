/* ##### Partners Area ##### */
.HomeDemo1 .partners {
    padding: 80px 0 130px 0;
    background-color: #272727;
}
.HomeDemo1 .partners .heading-section p {
    white-space: pre-wrap;
}

.HomeDemo1 .partner-box {
    border: 1px solid #474747;
    border-radius: 14px;
    text-align: center;
    vertical-align: middle;
    background-color: #272727;
    margin-bottom: 30px;
    transition: all .3s ease-in-out;
    min-width: 163px;
    min-height: 85px;
}
.HomeDemo1 .partner-box:hover{
    cursor: pointer;
}
