/* ##### Join THe Ecosystem Area CSS ##### */
.HomeDemo1 {
  .join-the-ecosystem {
    padding: 100px 0;
    position: relative;
    background-color: #000000;
    overflow: hidden;

    .img-area {
      background-color: #000000;
      height: 100%;
    }

    .network-img-area {
      margin-top: 0;
    }
  }

  .network-img-area {
    position: relative;
    z-index: 1;
    margin-top: 90px;

    img {
      height: auto;
    }
  }
}
