
/*///////////////////////////////////////////////////////////*/

/* ##### Footer Area ##### */

.footer-area {
    position: relative;
    z-index: 1;
    padding: 0;
    background-color: #d4d4d4 !important;
}
.contact_us_area {
    background-color: #121212;
    padding: 70px 0;
}

.contact_form .subscribe-btn {
    position: relative !important;
    z-index: 1 !important;
    min-width: 160px !important;
    height: 48px !important;
    line-height: 48px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    letter-spacing: 1px !important;
    display: inline-block !important;
    padding: 0 20px !important;
    text-align: center !important;
    background-size: 200% auto !important;
    color: #121212 !important;
    border-radius: 14px !important;
    background-color: #82DDFB;
    -webkit-transition: all 500ms !important;
    transition: all 500ms !important;
}

.subscribe-btn:hover {
    background-position: right center;
    color: #fff;
}

.footer-content-area{
    padding: 80px 0;
    color: #383838;
}
.footer-logo{
    margin-bottom: 15px
}
.footer-logo a{
    color: #383838;
    font-size: 20px
}
.footer-logo img{
    width: 110px;
    height: 36px;
}
.footer-side-thumbnail {
    position: absolute;
    width: 50%;
    top: 0;
    left: 0;
    height: 100%;
    background-size: cover;
    background-position: top center;
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
}

.copywrite_text > p {
    margin-bottom: 10px;
    color: #383838;
    font-size: 13px;
    white-space: pre-wrap;
}

.copywrite_text > p > a {
    color: #383838;
}

.footer-social-info a {
    font-size: 14px;
    color: #383838;
    margin-right: 10px;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}

.contact_info_area {
    display: flex;
    justify-content: space-between;
    gap: 120px;
}

.contact_info_area .contact_info h5 {
    font-size: 14px;
    color:#383838;
}

.contact_info_area .contact_info p {
    color: #383838;
    margin-bottom: 0;
    font-size: 14px;
}
.contact_info_area .contact_info a:hover p{
    color: #383838;
}
