/* ##### Our Mission Area CSS ##### */
.HomeDemo1 .our-mission-area{
    position: relative;
    background-image: -webkit-linear-gradient(#121212, #000000) !important;
    background-image: linear-gradient(#121212, #000000) !important;
}

@media (max-width: 992px){
    .HomeDemo1 .vertical-social{
        display: none !important;
    }
}

.HomeDemo1 .vertical-social {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    background: #202020;
    height: 155px !important;
    margin-left: 30px;
    border-radius: 40px;
    padding: 30px 15px;
    z-index: 999;
    transition: 1s;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid #303030;
}
.vertical-social ul {
    margin-bottom: 0;
}
.HomeDemo1 .vertical-social li{
    padding: 7px 0;
    text-align: center;
}
.HomeDemo1 .vertical-social li a{
    color: #fff;
    opacity: .6;
    font-size: 22px;
}
.HomeDemo1 .vertical-social li a:hover{
    opacity: 1
}
